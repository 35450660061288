import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Coordinates } from '~/api/types';
import { useMapEngineContext } from '~/components/MapEngineProvider';
import { useMapUtils } from '~/hooks';
import { selectShowRoutePolygons } from '~/reducers/mapSettingsSlice';
import { useSelector } from 'react-redux';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import {
    SupportedMaps,
    SupportedMapsCommonInterface,
    SupportedObjects,
    SupportedPolygonCommonInterface,
    SupportedPolygons,
    SupportedToolKits
} from '~/components/MapEngineProvider/types';
import { usePlanMapDisplayContext } from '~/components/MapPage/PlanMap/PlanMapDisplayContext';
import { selectSelectedMapRoutes } from '~/reducers/selectedMapRoutesSlice';

interface IPolygonOptions {
    color: string;
}

interface IRoutePolygon {
    clientRouteId: string;
    perimeterCoordinates: Coordinates[];
    options: IPolygonOptions;
}

export const RoutePolygon = ({
    perimeterCoordinates,
    options,
    clientRouteId
}: IRoutePolygon) => {
    const { mapToolKit, mapInstance } = useMapEngineContext();
    const { hoveredRouteMarker } = usePlanMapDisplayContext();
    const { isPlanRouteMode, mapRouteMode, isRoutesMode } = useMapUtils();
    const showRoutePolygons = useSelector(
        selectShowRoutePolygons(mapRouteMode as ConfigurableMapRouteMode)
    );
    const selectedMapRoutes = useSelector(selectSelectedMapRoutes);

    const polygonRef = useRef<SupportedPolygons | null>(null);
    const mapInstanceRef = useRef<SupportedMaps | null>(null);
    const mapToolKitRef = useRef<SupportedToolKits | null>(null);

    const polygon = useMemo(() => {
        if (!mapToolKit) {
            return null;
        }
        return mapToolKit.makePolygon(perimeterCoordinates, options);
    }, [perimeterCoordinates, options, mapToolKit]);

    useEffect(() => {
        mapInstanceRef.current = mapInstance;
        mapToolKitRef.current = mapToolKit;
    }, [mapInstance, mapToolKit]);

    const removePolygon = useCallback(() => {
        if (polygonRef.current && mapToolKitRef.current) {
            mapToolKitRef.current.removeMapObject(
                polygonRef.current as SupportedObjects,
                mapInstanceRef.current as SupportedMapsCommonInterface
            );
            polygonRef.current = null;
        }
    }, []);

    const drawPolygon = useCallback(
        (polygonToDraw) => {
            if (!mapToolKit || !mapInstance) {
                return;
            }
            mapToolKit.drawMapObject(
                polygonToDraw,
                mapInstance as SupportedMapsCommonInterface
            );
            polygonRef.current = polygonToDraw;
        },
        [mapToolKit, mapInstance]
    );

    useEffect(() => {
        return () => {
            removePolygon();
        };
        /* eslint-disable-next-line */
    }, []);

    useEffect(() => {
        if (!mapToolKit || !mapInstance) {
            return;
        }
        removePolygon();
        if (!showRoutePolygons || !isPlanRouteMode) {
            return;
        }

        if (!isRoutesMode && selectedMapRoutes.includes(clientRouteId)) {
            drawPolygon(polygon);
            return;
        }

        if (isRoutesMode) {
            drawPolygon(polygon);
        }
    }, [
        drawPolygon,
        removePolygon,
        isRoutesMode,
        selectedMapRoutes,
        hoveredRouteMarker,
        clientRouteId,
        polygon,
        mapToolKit,
        mapInstance,
        showRoutePolygons,
        isPlanRouteMode
    ]);

    useEffect(() => {
        if (!mapToolKit || !mapInstance || !polygon) {
            return;
        }

        if (clientRouteId === hoveredRouteMarker) {
            mapToolKit.emphasizePolygon(
                polygon as SupportedPolygonCommonInterface
            );
            drawPolygon(polygon);
        } else {
            mapToolKit.unEmphasizePolygon(
                polygon as SupportedPolygonCommonInterface
            );
        }
    }, [
        drawPolygon,
        hoveredRouteMarker,
        mapToolKit,
        mapInstance,
        clientRouteId,
        polygon,
        showRoutePolygons
    ]);

    return null;
};
